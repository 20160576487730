







import { Component, Vue, Prop } from 'vue-property-decorator'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'

@Component({
  components: {
    CoverWidget,
  },
})
export default class SectionWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean
}
