


































import { Component } from 'vue-property-decorator'
import { aboutUsService } from '@/services'
import ArticleWidget from '@/components/organsims/widgets/Article.vue'
import SectionWidget from '@/components/organsims/widgets/Section.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import LinksWidget from '@/components/organsims/widgets/Links.vue'
import Layout from '@/components/organsims/Layout.vue'
import View from '@/mixins/View'

@Component({
  components: {
    ArticleWidget,
    SectionWidget,
    CoverWidget,
    LinksWidget,
    Layout,
  },
})
export default class AboutUs extends View {
  widgets: any = []

  async created() {
    this.widgets = await this.fetch(aboutUsService)
  }
}
