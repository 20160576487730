






























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LinksWidget extends Vue {
  @Prop()
  model!: any
}
